import React from 'react';


let app = "OurTrees";
let rel = "noopener noreferrer";
let target = "_blank";

const VERSION = "1.11.0";

export const GEOSERVER_URL = "https://gis.landscape.itreetools.org/geoserver/landscape/wfs";

export const URLS = {
	home: "https://www.itreetools.org/",
	viable: "https://database.itreetools.org/#/viableLocations",
	email: "mailto:info@itreetools.org?subject=Intl Funding",
	eula: "https://help.itreetools.org/eula?embed=true",
	ourtrees: "https://ourtrees.itreetools.org",
	mytree: "https://mytree.itreetools.org",
	landscape: "https://landscape.itreetools.org",
};

export const ANCHORS = {
	home: <a href={URLS.home} rel={rel} target={target}>www.itreetools.org</a>,
	eula: <a href={URLS.eula} rel={rel} target={target}>EULA</a>,
	viability: <a href={URLS.viable} rel={rel} target={target}>supported by i-Tree<sup>&reg;</sup></a>,
	ourtrees: <a href={URLS.ourtrees} rel={rel} target={target}>OurTrees</a>,
	landscape: <a href={URLS.landscape} rel={rel} target={target}>i-Tree Landscape</a>,
	email: <a href={URLS.email}>Contact us</a>,
};

export const NAVBAR = {
	app: app,
	tagline:
		<span>
			A tree canopy{" "}
			<span className={"d-none d-sm-inline"}>
				assessment
			</span>
			{" "}tool.</span>,
	taglineClasses: "tagline",
	appNameClasses: "app-name dark-blue",
	navbarClasses: "blue-gradient",
}

export const LANDING = {
	app: app,
	version: VERSION,
	headline: "Discover the benefits of your community's trees!",
	intro:
		<span>
			Select a location and we'll estimate the amount of{" "}
			<span className={"fw-bold green"}>carbon dioxide</span>
			{" "}and{" "}
			<span className={"fw-bold brown"}>air pollution</span>
			{" "}its trees remove, as well as{" "}
			<span className={"fw-bold blue"}>stormwater</span>
			{" "}impacts.
		</span>,
	description: <small className={"fst-italic"}>
		OurTrees is a tool for assessing tree canopy.<br/>
		For benefits of individual trees, check out{" "}
		<a
			href={URLS.mytree}
			target={target}
			rel={rel}
		>
			MyTree
		</a>.
	</small>,
	locationWarning: <small className={"fst-italic"}>
		At this time, OurTrees {" "}
		<a
			href={URLS.viable}
			target={target}
			rel={rel}
		>
			supports
		</a>{" "}
		US locations.<br/>
		<a href={URLS.email}>
			Contact us
		</a> and let's explore funding opportunities<br/>
		to enable it for your municipality!
	</small>,
	eula: <small className={"fst-italic"}>
		Use of this tool indicates you accept our{" "}
		{/*FIXME: Move the EULA to modal*/}
		<a
			href={URLS.eula}
			target={target}
			rel={rel}
		>
			EULA.
		</a>
	</small>
}

export const REPORT = {
	headline: "OurTrees Benefits",
	bottom: {
		blurb: <p>Benefit estimates are based on USDA Forest Service research
			and are meant for guidance only. Visit {ANCHORS.home} to learn more.
			Get even more information at {ANCHORS.landscape}!</p>,
		valueDeclaration: "",
		abbreviations: <span>Abbreviations:
			<ul className={"list-unstyled"}>
				<li>CO<sub>2</sub> = Carbon dioxide</li>
				<li>PM<sub>2.5</sub> = Particulate matter 2.5 microns or less</li>
				<li>tn =Short ton (US)</li>
				<li>t = Tonne / metric ton</li>
				<li>MG/yr = Millions of gallons per year</li>
				<li>m<sup>3</sup>/yr = Cubic meters per year</li>
				<li>lb/yr = Pounds per year</li>
				<li>kg/yr = Kilograms per year</li>
			</ul></span>,
		footnotes: [
			<span>
				CO<sub>2</sub> equivalent is estimated by calculating how much
				atmospheric CO<sub>2</sub> is taken in by trees to provide the
				carbon stored in the tissues of individual trees.
			</span>,
		],
		poweredBy: "Landscape",
		version: <span>OurTrees <sub>{VERSION}</sub></span>,
	},
};
