import React, {useState} from "react";

import {
	Row,
	Col,
	Button,
	Input,
	InputGroup,
} from "reactstrap";

import {FontAwesomeIcon as FAI} from "@fortawesome/react-fontawesome";

import html2canvas from "html2canvas";

import "./social.scss";
import {
	DEVMODE,
	MYTREE,
} from "../../utilities/resources";


export const SocialSharing = (
	{
		body,
		footerId = "footer",
		emailSubject,
		fileName,
		url,
		canvasTarget,
		setFinePrintIsOpen,
	}
) => {
	let [copied, setCopied] = useState(false);

	let copyURL = async () => {
		document.querySelector("input.email-input").select();
		let timeout = 1000;
		await navigator.clipboard.writeText(url);
		setCopied(true);
		setTimeout(() => {
			setCopied(false);
		}, timeout);
	};

	let handleImageExport = async () => {
		setFinePrintIsOpen(true);
		let reportElement = document.getElementById(canvasTarget);
		const COMMON_OPTIONS = {x: -10, y: -10, scale: 3};

		const REPORT_CANVAS_OPTIONS = {
			width: reportElement.clientWidth + 20,
			height: reportElement.clientHeight + 20,
			...COMMON_OPTIONS,
		};
		let containerCanvas = document.createElement("canvas");
		let reportCanvas = await html2canvas(
			reportElement,
			REPORT_CANVAS_OPTIONS
		);
		containerCanvas.width = reportCanvas.width;
		containerCanvas.height = reportCanvas.height;
		let containerContext = containerCanvas.getContext("2d");
		containerContext.drawImage(reportCanvas, 0, 0);

		if (canvasTarget.includes("benefits") && !MYTREE) {
			let footerElement = document.getElementById(footerId);
			const FOOTER_CANVAS_OPTIONS = {
				width: reportCanvas.width + 20,
				height: footerElement.clientHeight + 20,
				...COMMON_OPTIONS,
			};
			let footerCanvas = await html2canvas(
				footerElement,
				FOOTER_CANVAS_OPTIONS
			);
			containerCanvas.height = reportCanvas.height + footerCanvas.height;
			containerContext.drawImage(reportCanvas, 0, 0);
			containerContext.drawImage(footerCanvas, 0, reportCanvas.height);
		}

		let downloadLink = document.createElement("a");
		downloadLink.setAttribute("download", fileName);
		let type = "image/png"; // This is the default "type parameter" of canvas.toBlob
		let quality = 1; // A Number between 0 and 1 indicating the image quality to be used when creating images using file formats that support lossy compression.
		containerCanvas.toBlob(
			(blob) => {
				let url = URL.createObjectURL(blob);
				downloadLink.setAttribute("href", url);
				downloadLink.click();
			},
			type,
			quality
		);
	};

	body = body + "%0A%0A" + encodeURIComponent(url);
	let copyButtonColor = copied ? "success" : "secondary"

	return (
		<section>
			<Row
				className={"mb-n1"}
				data-html2canvas-ignore={"true"}
			>
				<Col>
					<p className={"h4 text-center"}>
						Share
					</p>
				</Col>
			</Row>
			<Row
				className={"mb-2"}
				data-html2canvas-ignore={"true"}
			>
				<Col>
					<InputGroup>
						<Input
							className={"font-size-small email-input"}
							type={"text"}
							defaultValue={url}
						/>
						<Button
							outline
							title={"Copy to clipboard"}
							color={copyButtonColor}
							onClick={
								() => {
									copyURL()
								}
							}
						>
							<FAI icon={"copy"}/>
						</Button>
						<a
							title={"Send email"}
							className={"btn btn-outline-secondary border-start"}
							href={`mailto:?subject=${emailSubject}&body=${body}`}
						>
							<FAI className={"icon"} icon={"envelope"}/>
						</a>
					</InputGroup>
				</Col>
			</Row>
			{DEVMODE ?
				<Row
					className={"mb-3"}
					data-html2canvas-ignore={"true"}
				>
					<Col>
						<div
							className={"d-flex flex-column align-items-center"}>
							<Button
								color={"secondary"}
								outline
								size={"sm"}
								onClick={
									() => handleImageExport()
								}
							>
								<FAI className={"icon"} icon={"save"}/> Save as
								image
							</Button>
						</div>
					</Col>
				</Row>
				:
				null
			}
		</section>
	)
}
